exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[slug]-index-tsx": () => import("./../../../src/pages/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-[slug]-index-tsx" */),
  "component---src-pages-aktualnosci-[slug]-index-tsx": () => import("./../../../src/pages/aktualnosci/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-[slug]-index-tsx" */),
  "component---src-pages-aktualnosci-index-tsx": () => import("./../../../src/pages/aktualnosci/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-index-tsx" */),
  "component---src-pages-aktualnosci-kategoria-[slug]-index-tsx": () => import("./../../../src/pages/aktualnosci/kategoria/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-kategoria-[slug]-index-tsx" */),
  "component---src-pages-aktualnosci-kategoria-[slug]-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/aktualnosci/kategoria/[slug]/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-kategoria-[slug]-s-[pagenumber]-index-tsx" */),
  "component---src-pages-aktualnosci-kategoria-index-tsx": () => import("./../../../src/pages/aktualnosci/kategoria/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-kategoria-index-tsx" */),
  "component---src-pages-aktualnosci-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/aktualnosci/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-s-[pagenumber]-index-tsx" */),
  "component---src-pages-aktualnosci-tag-[slug]-index-tsx": () => import("./../../../src/pages/aktualnosci/tag/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tag-[slug]-index-tsx" */),
  "component---src-pages-aktualnosci-tag-[slug]-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/aktualnosci/tag/[slug]/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tag-[slug]-s-[pagenumber]-index-tsx" */),
  "component---src-pages-aktualnosci-tag-index-tsx": () => import("./../../../src/pages/aktualnosci/tag/index.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tag-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kategoria-[slug]-index-tsx": () => import("./../../../src/pages/kategoria/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-kategoria-[slug]-index-tsx" */),
  "component---src-pages-kategoria-[slug]-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/kategoria/[slug]/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-kategoria-[slug]-s-[pagenumber]-index-tsx" */),
  "component---src-pages-kategoria-index-tsx": () => import("./../../../src/pages/kategoria/index.tsx" /* webpackChunkName: "component---src-pages-kategoria-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-oferta-[slug]-[slug]-index-tsx": () => import("./../../../src/pages/oferta/[slug]/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-oferta-[slug]-[slug]-index-tsx" */),
  "component---src-pages-oferta-[slug]-index-tsx": () => import("./../../../src/pages/oferta/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-oferta-[slug]-index-tsx" */),
  "component---src-pages-outlet-[slug]-index-tsx": () => import("./../../../src/pages/outlet/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-outlet-[slug]-index-tsx" */),
  "component---src-pages-outlet-index-tsx": () => import("./../../../src/pages/outlet/index.tsx" /* webpackChunkName: "component---src-pages-outlet-index-tsx" */),
  "component---src-pages-outlet-kategoria-[slug]-index-tsx": () => import("./../../../src/pages/outlet/kategoria/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-outlet-kategoria-[slug]-index-tsx" */),
  "component---src-pages-outlet-kategoria-[slug]-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/outlet/kategoria/[slug]/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-outlet-kategoria-[slug]-s-[pagenumber]-index-tsx" */),
  "component---src-pages-outlet-kategoria-index-tsx": () => import("./../../../src/pages/outlet/kategoria/index.tsx" /* webpackChunkName: "component---src-pages-outlet-kategoria-index-tsx" */),
  "component---src-pages-outlet-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/outlet/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-outlet-s-[pagenumber]-index-tsx" */),
  "component---src-pages-realizacje-[slug]-index-tsx": () => import("./../../../src/pages/realizacje/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-[slug]-index-tsx" */),
  "component---src-pages-realizacje-index-tsx": () => import("./../../../src/pages/realizacje/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-index-tsx" */),
  "component---src-pages-realizacje-kategoria-[slug]-index-tsx": () => import("./../../../src/pages/realizacje/kategoria/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-kategoria-[slug]-index-tsx" */),
  "component---src-pages-realizacje-kategoria-[slug]-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/realizacje/kategoria/[slug]/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-kategoria-[slug]-s-[pagenumber]-index-tsx" */),
  "component---src-pages-realizacje-kategoria-index-tsx": () => import("./../../../src/pages/realizacje/kategoria/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-kategoria-index-tsx" */),
  "component---src-pages-realizacje-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/realizacje/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-realizacje-s-[pagenumber]-index-tsx" */),
  "component---src-pages-tag-[slug]-index-tsx": () => import("./../../../src/pages/tag/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-tag-[slug]-index-tsx" */),
  "component---src-pages-tag-[slug]-s-[pagenumber]-index-tsx": () => import("./../../../src/pages/tag/[slug]/s/[pagenumber]/index.tsx" /* webpackChunkName: "component---src-pages-tag-[slug]-s-[pagenumber]-index-tsx" */),
  "component---src-pages-tag-index-tsx": () => import("./../../../src/pages/tag/index.tsx" /* webpackChunkName: "component---src-pages-tag-index-tsx" */),
  "component---src-templates-aktualnosci-news-paginated-page-tsx": () => import("./../../../src/templates/aktualnosci/NewsPaginatedPage.tsx" /* webpackChunkName: "component---src-templates-aktualnosci-news-paginated-page-tsx" */),
  "component---src-templates-aktualnosci-news-post-item-tsx": () => import("./../../../src/templates/aktualnosci/NewsPostItem.tsx" /* webpackChunkName: "component---src-templates-aktualnosci-news-post-item-tsx" */),
  "component---src-templates-aktualnosci-offer-page-tsx": () => import("./../../../src/templates/aktualnosci/OfferPage.tsx" /* webpackChunkName: "component---src-templates-aktualnosci-offer-page-tsx" */),
  "component---src-templates-page-page-definition-tsx": () => import("./../../../src/templates/page/PageDefinition.tsx" /* webpackChunkName: "component---src-templates-page-page-definition-tsx" */),
  "component---src-templates-product-product-paginated-page-tsx": () => import("./../../../src/templates/product/ProductPaginatedPage.tsx" /* webpackChunkName: "component---src-templates-product-product-paginated-page-tsx" */),
  "component---src-templates-showcase-showcase-paginated-page-tsx": () => import("./../../../src/templates/showcase/ShowcasePaginatedPage.tsx" /* webpackChunkName: "component---src-templates-showcase-showcase-paginated-page-tsx" */)
}

