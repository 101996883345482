import { createClient, fetchExchange } from "urql";
import { cacheExchange } from "@urql/exchange-graphcache";
import fetch from "isomorphic-fetch";

export const client = createClient({
  url: process.env.GATSBY_GQL_API_URL!,
  fetch,
  exchanges: [fetchExchange],

  // fetchOptions: () => {
  //   return {
  //     mode: "no-cors",
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  // },
  // exchanges: [
  //   cacheExchange({
  //     optimistic: {
  //       updatePost: (variables, _cache, _info) => {
  //         console.log(variables);
  //         return {
  //           __typename: "Post",
  //           id: variables.id,
  //           votes: variables.votes,
  //         };
  //       },
  //     },
  //   }),
  // fetchExchange, // async, so it needs to go last
  // ],
});
